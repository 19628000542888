import React from "react";
import FilmVicLogo from "../../images/Film_Victoria_State_Gov_logo.svg";
import { FaTwitter, FaItchIo, FaLinkedinIn } from "react-icons/fa";
import styles from "./Forest.module.scss";

const Forest = () => {
  return (
    <div className={styles.Forest}>
      <div className={styles.ForestWrapper}>
        <div className={styles.MoreAboutWrapper}>
          <div className={styles.MoreAbout}>
            <div className={styles.MoreAboutMobile}>
              <h2>Acknowledgements</h2>
              <p className={styles.Support}>
                This game was shaped by you, the Indie Game Community.
              </p>
              <p>
                Check out my{" "}
                <a href="https://us4.campaign-archive.com/home/?u=8ae19eeeff391cbeffd2199aa&id=6b363d9f0f">
                  past&nbsp;newsletters
                </a>{" "}
                to learn more about the process.
              </p>
            </div>
            <div>
              <p className={styles.LogoAck}>Released with the assistance of</p>
              <img
                className={styles.Logo}
                src={FilmVicLogo}
                alt="Film Victoria Logo"
              />
            </div>
          </div>
        </div>
        <div className={styles.Sign}>
          <h2 className={styles.NotDesktop}>Talk to Me</h2>
          <div className={[styles.Press, styles.NonTablet].join(" ")}>
            <h2>Press</h2>
            <p>
              If you would like to write or talk about Room to Grow please{" "}
              <a href="mailto:support@mischkakamener.com">
                get&nbsp;in&nbsp;touch.
              </a>
            </p>
          </div>
          <div className={styles.SignText}>
            <div className={styles.SignLinks}>
              <p>
                <FaTwitter />
                &nbsp;
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/mischkakamener"
                >
                  @mischkakamener
                </a>
              </p>
              <p>
                <FaItchIo className={styles.Itchio} />
                &nbsp;
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://itch.io/profile/mischkakamener"
                >
                  Itch.io
                </a>
              </p>
              <p>
                <FaLinkedinIn className={styles.LinkedIn} />
                &nbsp;
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/in/mischka-kamener/"
                >
                  Linkedin
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={[styles.Press, styles.Tablet].join(" ")}>
        <h2>Press</h2>
        <p>
          If you would like to write or talk about Room to Grow please{" "}
          <a href="mailto:support@mischkakamener.com">
            get&nbsp;in&nbsp;touch.
          </a>
        </p>
      </div>
    </div>
  );
};

export default Forest;
