import React, { useRef } from "react";
import { useInView } from "react-intersection-observer";
import classnames from "classnames";
import styles from "./Newsletter.module.scss";

const Newsletter = () => {
  const { ref, inView } = useInView({ threshold: 0.6, triggerOnce: true });
  const inputRef = useRef();
  const fakeInputRef = useRef();

  return (
    <div className={styles.Newsletter} ref={ref} id="newsletter-signup">
      <h2>Keep Your Roots to the Ground</h2>
      <p>Sign up for updates and news about the game</p>
      <div
        className={classnames([styles.Cactus, { [styles.Visible]: inView }])}
      >
        <form
          action="https://mischkakamener.us4.list-manage.com/subscribe/post?u=8ae19eeeff391cbeffd2199aa&amp;id=6b363d9f0f"
          method="post"
          target="_blank"
          noValidate
        >
          <span className={styles.VisuallyHidden}>
            Enter your email to receive updates about the game
            <input
              type="text"
              name="b_8ae19eeeff391cbeffd2199aa_6b363d9f0f"
              tabIndex="-1"
              ref={fakeInputRef}
            />
          </span>

          <input
            type="email"
            ref={inputRef}
            name="EMAIL"
            placeholder="Email address"
            id="mce-EMAIL"
          />
          <button
            className={styles.Submit}
            type="submit"
            value="Subscribe"
            name="subscribe"
          >
            Sign up
          </button>
        </form>
      </div>
    </div>
  );
};

export default Newsletter;
