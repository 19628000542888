import React from "react";
import styles from "./App.module.scss";
import Header from "./components/Header/Header";
import Ice from "./components/Ice/Ice";
import Newsletter from "./components/Newsletter/Newsletter";
import Forest from "./components/Forest/Forest";

function App() {
  return (
    <div className={styles.App}>
      <Header />
      <Ice />
      <Newsletter />
      <Forest />
      <div className={styles.Footer}>
        <p>Room to Grow Game by <a href="mailto:support@mischkakamener.com">Mischka Kamener</a> © 2021</p>
      </div>
    </div>
  );
}

export default App;
