import React from "react";
import Logo from "../../images/logo-desert-colours.svg";
import { FaTwitter, FaSteam } from "react-icons/fa";
import YouTube from "react-youtube";
import styles from "./Header.module.scss";

const Header = () => {
  return (
    <header className={styles.Header}>
      <div className={styles.Links}>
        <a target="_blank" rel="noreferer" href="https://twitter.com/mischkakamener">
          <FaTwitter />
        </a>
        <a target="_blank" rel="noreferer" href="https://store.steampowered.com/app/1471960/">
          <FaSteam />
        </a>
      </div>
      <div className={styles.LogoWrapper}>
        <img className={styles.Logo} src={Logo} alt="Room to Grow" />
      </div>

      <h2>Plant your succulent siblings in this prickly puzzle game</h2>

      <YouTube
        containerClassName={styles.VideoWrapper}
        className={styles.Video}
        videoId={"642IFF9C70w"}
      />
    </header>
  );
};

export default Header;
