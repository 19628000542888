import React from "react";
import classnames from "classnames";
import pottedCactus from "../../images/potted-cactus.png";
import styles from "./Ice.module.scss";

const Ice = () => {
  const MILLIS_IN_DAY = 1000 * 3600 * 24;
  let daysUntilRelease = Math.ceil(
    (new Date("02/25/2021") - Date.now()) / MILLIS_IN_DAY
  );
  return (
    <div className={styles.Ice}>
      <div className={classnames(styles.Clearing, {
            [styles.Upcoming]: daysUntilRelease > 0,
          })}>
        <div
          className={classnames(styles.Content, {
            [styles.Upcoming]: daysUntilRelease > 0,
          })}
        >
          {daysUntilRelease <= 0 ? (
            <>
              <h2>
                Now Blooming <br />
                on Steam!
              </h2>
              <img
                className={styles.BloomingCactus}
                src={pottedCactus}
                alt="small forest cactus"
              />
              <div className={classnames(styles.Mobile, styles.SteamLink)}>
                <a className={styles.GoToSteam} href="https://store.steampowered.com/app/1471960/">Visit Room to Grow <br/> on Steam</a>
              </div>
            </>
          ) : (
            <>
              <h2>Blooming Soon on Steam</h2>
              <div className={styles.DayCounterWrapper}>
                <div className={styles.DayCounter}>
                  <span>{daysUntilRelease}</span>
                </div>
                <h2>day{daysUntilRelease === 1 ? "" : "s"}&nbsp;to&nbsp;go</h2>
              </div>
              <div className={classnames(styles.Mobile, styles.SteamLink)}>
                <a className={styles.GoToSteam} href="https://store.steampowered.com/app/1471960/">Add to your Steam Wishlist</a>
              </div>
            </>
          )}
        </div>
        <div className={classnames(styles.NotMobile, styles.SteamWidgetWrapper)}>
          <iframe
            title="Steam widget"
            className={[styles.NotMobile, styles.SteamWidget].join(' ')}
            src="https://store.steampowered.com/widget/1471960/"
            frameBorder="0"
            width="100%"
            height="190"
          ></iframe>
        </div>


        {daysUntilRelease <= 0 && (
            <>
              <img
                className={classnames(styles.Tablet, styles.BloomingCactus)}
                src={pottedCactus}
                alt="small forest cactus"
              />
              <img
                className={classnames(styles.Tablet, styles.BloomingCactusTwo)}
                src={pottedCactus}
                alt="small forest cactus"
              />
            </>
          )}
      </div>
    </div>
  );
};

export default Ice;
